import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import unified from "unified"
import remarkParse from "remark-parse"
import remark2react from "remark-react"

const intro = `
Ahoi!  My name is Dario Hamidi and I write about programming here.

If you want to know about what I've done so far, check out my
[CV](https://dario-hamidi.de).

`
const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  let posts = edges.map(edge => edge.node)
  return (
    <Layout>
      <SEO title="Home" />
      <article className="px-5">
        {processMarkdown(intro)}
        {posts.map(post => {
          return (
            <section key={post.frontmatter.path} className="mb-1 p-2 pl-0">
              <p>
                <time datetime={post.frontmatter.date} className="text-xs">
                  {post.frontmatter.date}
                </time>
              </p>
              <p>
                <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
              </p>
              {post.frontmatter.excerpt ? <PostSummary post={post} /> : <></>}
            </section>
          )
        })}
      </article>
    </Layout>
  )
}

function PostSummary({ post }) {
  let summary = processMarkdown(post.frontmatter.excerpt)
  summary.props.children.push(
    <Link to={post.frontmatter.path}> [Read more]</Link>
  )
  return <div className="text-sm">{summary}</div>
}

function processMarkdown(text) {
  let result = unified()
    .use(remarkParse)
    .use(remark2react)
    .processSync(text).contents
  console.log(result)
  return result
}
export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            excerpt
            path
          }
        }
      }
    }
  }
`
export default IndexPage
